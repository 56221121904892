@tailwind base;
@tailwind components;
@tailwind utilities;

html { 
    scroll-behavior: smooth;
}

.copyright {
    font-family: 'Sora', sans-serif;
    font-weight: 300;
}

.pink-dart {
    background: linear-gradient(275.54deg, #E27396 -10.52%, #D5839F 130.51%);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.pink-more {
    background: #F8DDE6;
}

.purple-dart {
    background: linear-gradient(308.7deg, #402E6A -6.32%, #5D658A 119.09%);
}

.purple-more {
    color: #402E6A;
}

.purple-young {
    background: #EBE2FF;
}

.purple-trans {
    background: #402E6A;
}

.black-more {
    color: #2D2D2D;
}

.white-more {
    background: rgba(255, 255, 255, 0.07);
}

.white-blur {
    color: rgba(255, 255, 255, 0.6);
}

.prata {
    font-family: 'Prata', serif;
}

.sora {
    font-family: 'Sora', sans-serif;
} 

.h-764 {
    height: 700px;
}

.s-text {
    text-shadow: 0.1em 0.1em 0.2em rgb(64 46 105 / 85%);
}

.border-purple {
    border-color: #402E6A;
    border-bottom-width: 1px;
}

.border-result {
    border-color: #402E6A;
    border-width: 1px;
}

.border-full-purple {
    border-color: #402E6A;
    border-width: 1px;
}

.border-pink {
    border-color: #E27396;
    border-bottom-width: 2px;
}

.border-l-pink {
    border-color: #E27396;
    border-left-width: 8px;
}

.border-b-pink { 
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-color: linear-gradient(275.54deg, #E27396 -10.52%, #D5839F 130.51%);;
}

.purple-mix {
    background: #402E6A;
    mix-blend-mode: normal; 
}

.line-clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #402E6A;
  font-family: 'Sora', sans-serif;
  font-weight: 200;
  /* font-size: 15px; */
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #402E6A;
  font-family: 'Sora', sans-serif;
  font-weight: 200;
  /* font-size: 15px; */
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #402E6A;
  font-family: 'Sora', sans-serif;
  font-weight: 200;
  /* font-size: 15px; */
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #402E6A;
  font-family: 'Sora', sans-serif;
  font-weight: 200;
  /* font-size: 15px; */
} 

/* .swiper-container {
    width: 500px;
    height: 500px;
} */

.headBul .swiper-pagination {
    text-align: left!important;
    bottom: 33%!important;
    left: 8%!important;
}

.eventBul .swiper-pagination {
    text-align: center!important;
    bottom: 0%!important; 
}

.headDes {
    text-align: left!important;
    bottom: 33%!important;
    left: 8%!important;
    width: 35%;
    position: absolute;
}

.promotion .swiper-pagination {
    text-align: right !important;
}

.promotions .swiper-pagination {
    text-align: center !important;
}

.swiper-pagination-bullet { 
    width: 8px !important;
    height: 8px !important;
    display: inline-block !important;
    border: 1px solid #402E6A !important; 
    border-radius: 50% !important;
    background: #ffffff !important;
    opacity: 1 !important; 
}

.swiper-pagination-bullet-active {
    background: #402E6A !important;
} 

.active {
    color: white;
    font-weight: 600;
    border-left-width: 6px;
    border-left-color: #E27396;
    border-left-style: solid;
    padding-left: 8px;
}

.active-alfa {
    background: #F8DDE6; 
    border-radius: 50%;
    font-weight: bold;
}

.arrow-down {
    background: transparent;
    background-image: url("/img/chevron-bottom.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 12px;
    background-size: 16px 16px;
} 

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.pagination-bar {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -10px !important;
    left: 0 !important;
    width: 100% !important;
} */

/* @media only screen and (min-device-width: 350px) and (max-device-width: 413px) { 
    .bg-mobile {
        background-image: url('../public/img/bgheadmob.jpg')!important;
    }
    .h-480 {
        height: 580px;
    }
    .logo div {
        width: 37px !important;
        height: 40px !important;
    }
    .swiper-container {
        width: 330px;
        height: 330px;
    }
} */

/* @media only screen and (min-device-width: 414px) and (max-device-width: 540px) { 
    .bg-mobile {
        background-image: url('../public/img/bgheadmob.jpg')!important;
    }
    .h-230 {
        height: 690px !important;
    }
    .logo div {
        width: 47px !important;
        height: 50px !important;
    }
    .swiper-container {
        width: 350px;
        height: 350px;
    }
} */

/* @media only screen and (min-device-width: 541px) and (max-device-width: 639px) { 
    .bg-header {
        background-image: url('../public/img/bghead.jpg')!important;
    }
    .h-230 {
        height: 380px !important;
    }
    .logo div {
        width: 57px !important;
        height: 60px !important;
    }
    .swiper-container {
        width: 450px;
        height: 450px;
    }
} */

/* @media only screen and (min-device-width: 640px) and (max-device-width: 834px) {  
    .h-230 {
        height: 440px !important;
    }
    .bg-header {
        background-image: url('../public/img/bghead.jpg')!important;
    }
    .logo div {
        width: 67px !important;
        height: 70px !important;
    }
    .swiper-container {
        width: 450px;
        height: 450px;
    }
} */

/* @media only screen and (min-device-width: 835px) and (max-device-width: 1062px) { 
    .h-450 {
        height: 550px;
    }
    .bg-header {
        background-image: url('../public/img/bghead.jpg')!important;
    }
    .logo div {
        width: 77px !important;
        height: 80px !important;
    }
} */

/* @media only screen and (min-device-width: 1063px) and (max-device-width: 1300px) { 
    .h-450 {
        height: 675px;
    }
    .bg-header {
        background-image: url('../public/img/bghead.jpg')!important;
    }
    .logo div {
        width: 87px !important;
        height: 90px !important;
    }
} */

/* @media only screen and (min-device-width: 1352px) and (max-device-width: 1500px) { 
    .h-450 {
        height: 800px;
    }
    .bg-header {
        background-image: url('../public/img/bghead.jpg')!important;
    }
    .logo div {
        width: 87px !important;
        height: 90px !important;
    }
} */

/* @media only screen and (min-device-width: 1501px) and (max-device-width: 2150px) { 
    .h-450 {
        height: 1094px;
    }
    .bg-header {
        background-image: url('../public/img/bghead.jpg')!important;
    }
    .logo div {
        width: 87px !important;
        height: 90px !important;
    }   
} */